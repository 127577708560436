import Vue from 'vue'
import App from './App.vue'
import Router from 'vue-router'
import store from './store'
import './scss/styles.scss'
import Buefy from 'buefy'
import '@fortawesome/fontawesome-free/css/all.css'
import VueMeta from 'vue-meta'
import VueI18n from 'vue-i18n'
import en from './locales/en.json'
import nl from './locales/nl.json'
import { routes } from './routes.js'
 
Vue.use(Router);
const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior (to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition
      } else {
        return { x: 0, y: 0 }
      }
    }
});


 

// const router = new VueRouter({
//   mode: 'history',
//   // base: baseURL,
//   base: process.env.BASE_URL,
//   routes,
//   scrollBehavior (to, from, savedPosition) {
//     if (savedPosition) {
//       return savedPosition
//     } else {
//       return { x: 0, y: 0 }
//     }
//   }
  
  

// })

Vue.config.productionTip = false
Vue.use(Buefy, {
  defaultIconPack: 'fas'
  // defaultFieldLabelPosition: 'on-border'
})

Vue.use(VueMeta)

Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: 'nl',
  fallbackLocale: 'nl',
  messages: {
      en: en,
      nl: nl,
  },
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
