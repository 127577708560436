<template>
  <footer class="footer">
    <div class="content has-text-centered has-text-light container">
      <div class="columns">
        <!-- <div class="column is-6">
          <h6 class="has-text-light">
            Over ons
          </h6>
          <p class="has-text-center">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </p>
        </div> -->
        <div class="column">
          <h2 class="subitle is-size-5 has-text-light">
            {{ $t("footer.Social_media") }}
          </h2>
          <!-- <h6 class="has-text-light">
            Volg ons
          </h6> -->
          <div class="mt-5">
            <a href="https://www.youtube.com/@peterslegers/" target="_blank">
              <b-icon
                pack="fab"
                icon="youtube"
                size="is-medium"
                class="has-text-light"
              />
            </a>
            <a href="https://www.instagram.com/peterslegers/" target="_blank">
              <b-icon
                pack="fab"
                icon="instagram"
                size="is-medium"
                class="has-text-light"
              />
            </a>
            <a href="https://www.facebook.com/peterslegersart" target="_blank">
              <b-icon
                pack="fab"
                icon="facebook"
                size="is-medium"
                class="has-text-light"
            /></a>
            <a href="https://www.linkedin.com/in/peterslegers" target="_blank">
              <b-icon
                pack="fab"
                icon="linkedin"
                size="is-medium"
                class="has-text-light"
              />
            </a>
          </div>
        </div>
        <div class="column">
          <h2 class="subitle is-size-5 has-text-light">
            {{ $t("footer.Sign_up_newsletter") }}
          </h2>

          <form
            id="mc-embedded-subscribe-form"
            action="https://peterslegers.us14.list-manage.com/subscribe/post?u=cac5c948ba022e83dbaf5b7f3&amp;id=00913a246e"
            method="post"
            name="mc-embedded-subscribe-form"
            class="validate"
            target="_blank"
            novalidate
          >
            <input
              id="mce-EMAIL"
              type="email"
              value=""
              name="EMAIL"
              class="email-input"
              :placeholder="$t('footer.Email_address')"
              required
            />
            <br />
            <b-button
              class="mt-3"
              type="is-link"
              native-type="submit"
              size="is-small"
            >
              {{ $t("footer.Sign_up") }}
            </b-button>
          </form>
        </div>
        <div class="column">
          <h2 class="subitle is-size-5 has-text-light">Contact</h2>
          <div class="has-text-left m-20">
            <ul class="no-bullets">
              <li>
                <a href="mailto:art@peterslegers.com">
                  <b-icon icon="envelope" />art@peterslegers.com
                </a>
              </li>
              <li>
                <a href="tel:+3-161-403-4470">
                  <b-icon icon="phone" />+31 (0)6 14 03 44 70
                </a>
              </li>
              <li><b-icon icon="map-pin" />Stenenwal 18b</li>
              <li><b-icon icon="location-arrow" />6221GA Maastricht</li>
            </ul>
          </div>
        </div>
        <!-- <div class="column">
          <h6 class="has-text-light">
            Contact
          </h6>
          <b-icon icon="envelope" />
          <b-icon icon="phone" />
        </div> -->
      </div>
    </div>
    <!-- <hr class="hr"> -->
    <div class="columns">
      <div class="column has-text-centered">
        <b-icon pack="far" icon="copyright" /> 2022 Peter Slegers
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  methods: {},
};
</script>

<style lang="scss" scoped>
.footer {
  bottom: 0;
  background-color: rgba(black, 1);
  margin-bottom: 0;
  padding-bottom: 0;
  margin-top: auto;
  // border-top: 0.1rem rgba($color: $link, $alpha: 1) solid;
}

ul.no-bullets {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}

hr {
  height: 0px;
  border: 0;
  border-top: 1px solid #eee;
  //  border-top: 1px solid rgba(white, 0.1);
}

.email-input {
  border: none;
  border-bottom: 1px solid white;
  transition: width 0.4s ease-in-out;
  background: transparent;
  // border: none;
  color: white;
  width: 50%;
}

.email-input:focus {
  border-bottom: 2px solid white;

  outline: none !important;
  width: 70%;
  //  border: none
}
</style>
