<template>
  <div>
    <div id="app">
      <Navbar />

      <transition name="component-fade" mode="out-in">
        <router-view />
      </transition>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "./components/navbar.vue";
import Footer from "./components/footer.vue";
// import i18n from 'vue-i18n'

export default {
  name: "App",
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Peter Slegers",
    // all titles will be injected into this template
    titleTemplate: "%s | Peter Slegers",
    meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content:
          "Maastrichtse kunstenaar die ambachtelijk kunst combineert met hedendaagse techniek",
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },
  i18n: {
    // locale: 'en',
  },
  components: {
    Navbar,
    Footer,
  },
  mounted: () => {
    // console.log($i18n.locale)
  },
};
</script>

<style lang="scss">
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 300ms ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.text-width {
  display: flex;
  align-items: center;
  flex-direction: column;

  p {
    margin: 20px;
    max-width: 700px;
  }
}
</style>
