export default [
    // {
    // key: 161801,
    // title: 'Dit is de titel',
    // subtitle: 'Dit is de subtitle',
    // date: '20-10-2021',
    // image: '1618002.jpg',
    // category: 'General'
    // },
    {
      key: 161809,
      title: 'Liggend naar kunst kijken, het kan in Museum Valkenburg: alsof je aan het mediteren bent tussen prachtige beelden',
      link: 'https://mcusercontent.com/cac5c948ba022e83dbaf5b7f3/images/97f33120-bcbc-f45e-6b53-292755f5e4b5.jpg',
      journal: 'De Limburger',
      date: '09-03-2023',
      image: 'pers_delimburger.jpg',
      onHomePage: true,
    },
    {
      key: 161808,
      title: 'Somerense kunstenaar smeedt aan de Maas zijn levenswerk, met hulp van dochter en zonen ',
      link: 'https://mcusercontent.com/cac5c948ba022e83dbaf5b7f3/images/a8238fd7-3a48-a515-e0b2-4b39e87856b8.jpeg',
      journal: 'Eindhovens Dagblad',
      date: '21-02-2023',
      image: 'pers_edtrilogica.jpg',
      onHomePage: false,
    },
    {
      key: 161807,
      title: 'In tuin, schuur, kantine en kerk: tijdens Losbroek is kunst overal te vinden',
      link: 'https://www.bd.nl/uden-veghel-e-o/in-tuin-schuur-kantine-en-kerk-tijdens-losbroek-is-kunst-overal-te-vinden~a90b29f9/?fbclid=IwAR0X6fK-qwAKHABgROLqeUsLAN_nWyZEOH4ed7W5sLEtH23bgvYTMNZ2VrI',
      journal: 'Brabants Dagblad',
      date: '28-08-2022',
      image: 'pers_babantsdagblad.webp',
      onHomePage: false,
    },
    {
      key: 161806,
      title: 'Tentoonstelling Op Drift in de Geertruidskerk toont kracht van water',
      link: 'https://www.uitgeverijemdejong.nl/weekbladen/de-langstraat/',
      journal: 'Weekblad de Langstraat',
      date: '24-11-2021',
      image: 'pers_weekblad.jpg',
      onHomePage: false,

    },
    {
      key: 161805,
      title: 'Vooruitkijken naar de ramp, de mens en het water van ‘de vreeselike vloed’',
      link: 'https://www.volkskrant.nl/ws-b6305655b',
      journal: 'De Volkskrant',
      date: '20-11-2021',
      image: 'pers_volkskrant.jpg',
      onHomePage: false,
    },
    {
      key: 161804,
      title: 'Een draaikolk van verhalen; in Op Drift voel je 600 jaar kracht van het water',
      link: 'https://www.bndestem.nl/oosterhout/een-draaikolk-van-verhalen-in-op-drift-voel-je-600-jaar-kracht-van-het-water~a02a468d/',
      journal: 'BN de stem',
      date: '18-11-2021',
      image: 'pers_bndestem.webp',
      onHomePage: false
    },
    {
      key: 161803,
      title: 'Op Drift: hoe een kerk werd getroffen door de Sint-Elisabethsvloed',
      link: 'https://kro-ncrv.nl/op-drift-hoe-een-kerk-werd-getroffen-door-de-sint-elisabethsvloed',
      journal: 'KRO NCRV',
      date: '16-11-2021',
      image: 'pers_kroncrv.png',
      onHomePage: false
    },

    {
      key: 161802,
      title: 'Hoe een middeleeuwse kerk opnieuw wordt verzwolgen door het water',
      link: 'https://www.omroepbrabant.nl/nieuws/3990151/hoe-een-middeleeuwse-kerk-opnieuw-wordt-verzwolgen-door-het-water',
      journal: 'Omroep Brabant',
      date: '15-11-2021',
      image: 'pers_omroepbrabant.png',
      onHomePage: false,
    },
    {
      key: 161801,
      title: 'In een mysterieuze crypte aanschuiven bij het laatste avondmaal: ‘Niets is wat het lijkt’',
      link: 'https://www.omroepbrabant.nl/nieuws/3328681/in-een-mysterieuze-crypte-aanschuiven-bij-het-laatste-avondmaal-niets-is-wat-het-lijkt',
      journal: 'Omroep Brabant',
      date: '20-01-2021',
      image: 'pers_omroepbrabant_aantafel.png',
      onHomePage: false,
    },

  ]