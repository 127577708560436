export default [
  {
    key: 1618001,
    name: "Museum Valkenburg",
    location: "Valkenburg",
    description: "Expositie",
    period: "Juni 2020 - Augustus 2020",
    image: "museum_valkenburg.jpg",
    link: "https://www.museumvalkenburg.nl/agenda/aan-tafel-door-peter-slegers/",
    project: "aantafel",
    status: "closed",
  },
  {
    key: 1618002,
    name: "Geertruidskerk",
    location: "Geertruidenberg",
    description: "Expositie & arrangementen",
    period: "Juni 2021 - Augustus 2021",
    image: "geertruidskerk_geertruidenberg.jpg",
    link: "https://www.museumvalkenburg.nl/agenda/aan-tafel-door-peter-slegers/",
    project: "aantafel",
    status: "closed",
  },
  {
    key: 1618003,
    name: "Kasteel dAspremont-Lynden",
    location: "Oud-rekem (B)",
    description: "Expositie",
    period: "Augustus 2021 - September 2021",
    image: "kasteel_oudrekem.jpg",
    // link: 'https://www.geertruidskerk.nl/mogelijkheden/opdrift',
    project: "aantafel",
    status: "closed",
  },
  {
    key: 1618004,
    name: "Geertruidskerk",
    location: "Geertruidenberg",
    description: 'Expositie "Op Drift"',
    period: "Nov 2021 - Sept 2022",
    image: "geertruidskerk_geertruidenberg.jpg",
    link: "https://www.geertruidskerk.nl/mogelijkheden/opdrift",
    project: "ondergaan",
    status: "closed",
  },
  {
    key: 1618006,
    name: "Oranjekerk",
    location: "Amsterdam",
    description: "Expositie",
    period: "Maart 2022",
    image: "oranjekerk_amsterdam.jpg",
    link: "https://www.oranjekerkamsterdam.nl",
    project: "aantafel",
    status: "closed",
  },
  {
    key: 1618007,
    name: "Willem de Zwijgerkerk",
    location: "Amsterdam",
    description: "Expositie",
    period: "April 2022",
    image: "willemdezwijgerkerk_amsterdam.jpg",
    link: "https://www.willemdezwijgerkerk.nl",
    project: "aantafel",
    status: "closed",
  },
  {
    key: 1618008,
    name: "Museum Krona",
    location: "Uden",
    description: "Expositie",
    period: "10 April - 22 Mei",
    image: "museum_krona.jpg",
    link: "https://www.museumkrona.nl/nl/",
    project: "aantafel",
    status: "closed",
  },
  {
    key: 1618005,
    name: "OLV van Lourdes",
    location: "Maastricht",
    description: "Inloop en beschouwing",
    period: "27 - 28 November 2021",
    image: "olv_maastricht.jpeg",
    link: "https://www.lourdeskerkmaastricht.net/",
    project: "aantafel",
    status: "closed",
  },
  {
    key: 1618009,
    name: "Galerie Peter Slegers",
    location: "Maastricht",
    description: "Expositie",
    period: "3 juni - 3 juli (verlengd tot 21 aug)",
    image: "atelier_overstijgen.jpg",
    link: "https://www.peterslegers.com/overstijgen",
    project: "overstijgen",
    status: "closed",
  },
  {
    key: 1618010,
    name: "Losbroek!",
    location: "Sint-Antonius van Paduakerk | Loosbroek",
    description: "Kunstfestival",
    period: "28 - 29 augustus 2022",
    image: "losbroek_loosbroek.jpg",
    link: "https://www.debkk.nl/",
    project: "aantafel",
    status: "closed",
  },
  {
    key: 1618011,
    name: "Losbroek!",
    location: "Lunenburg | Loosbroek",
    description: "Kunstfestival",
    period: "28 - 29 augustus 2022",
    image: "losbroek_loosbroek.jpg",
    link: "https://www.debkk.nl/",
    project: "ondergaan",
    status: "closed",
  },
  {
    key: 1618010,
    name: "Galerie Peter Slegers",
    location: "Maastricht",
    description: "Sneak preview",
    period: "14 okt - 6 nov 2022",
    image: "atelier_overstijgen.jpg",
    link: "https://www.peterslegers.com/kalender",
    project: "trilogica",
    status: "closed",
  },
  {
    key: 1618012,
    name: "Losbroek!",
    location: "Kerkzicht | Loosbroek",
    description: "Kunstfestival",
    period: "28 - 29 augustus 2022",
    image: "losbroek_loosbroek.jpg",
    link: "https://www.debkk.nl/",
    project: "overstijgen",
    status: "closed",
  },
  {
    key: 1618020,
    name: "Museum Valkenburg",
    location: "Valkenburg",
    description: "Expositie",
    period: "Februari - april 2023",
    image: "museum_valkenburg.jpg",
    link: "https://www.museumvalkenburg.nl/",
    project: "trilogica",
    status: "closed",
  },

  {
    key: 1618021,
    name: "Galerie Peter Slegers",
    location: "Maastricht",
    description: "Open atelier",
    period: "2023",
    image: "atelier_overstijgen.jpg",
    link: "https://www.peterslegers.com/",
    project: "goudengloed",
    status: "closed",
  },

  {
    key: 1618022,
    name: "Galerie Peter Slegers",
    location: "Maastricht",
    description: "Maastricht Gallery Weekend",
    period: "20 - 21 - 22 okt 2023",
    image: "atelier_overstijgen.jpg",
    link: "https://maastrichtgalleryweekend.nl/",
    project: "goudengloed",
    status: "closed",
  },

  {
    key: 1618023,
    name: "Cultuurtoren Honsoirde",
    location: "Nieuwkuijk",
    description: "Expositie",
    period: "12 nov - 31 dec 2023",
    image: "honsoirde_nieuwkuijk.jpg",
    link: "https://www.honsoirde.nl/event/project-aan-tafel/",
    project: "aantafel",
    status: "closed",
  },

  {
    key: 1618024,
    name: "De Einder",
    location: "Someren-Eind",
    description: "Expositie",
    period: "28 maart - 21 april 2024",
    image: "de-einder.jpg",
    link: "https://gemeenschapshuis-de-einder.nl/aan-tafel-van-peter-slegers/",
    project: "aantafel",
    status: "closed",
  },

  {
    key: 1618025,
    name: "Ertha",
    location: "Maastricht",
    description: "Groep Expositie",
    period: "7 maart - 12 april 2024",
    image: "atelier_overstijgen.jpg",
    // link: "https://gemeenschapshuis-de-einder.nl/aan-tafel-van-peter-slegers/",
    project: "ertha",
    status: "closed",
  },

  {
    key: 1618026,
    name: "Het Steiger",
    location: "Rotterdam",
    description: "Expositie",
    period: "oktober - november 2024",
    image: "steiger_rotterdam.jpg",
    link: "https://kerken010.nl/kerken/citykerk-het-steiger-sint-dominicus/",
    project: "aantafel",
    status: "announced",
  },

  {
    key: 1618098,
    name: "Uw locatie?",
    location: "Uw plaats?",
    description: "AAN TAFEL",
    period: "Neem gerust contact op",
    // image: 'vraagteken.jpg',
    // link: '',
    project: "aantafel",
    status: "none",
  },
  {
    key: 1618099,
    name: "Uw locatie?",
    location: "Uw plaats?",
    description: "ONDERGAAN",
    period: "Neem gerust contact op",
    // image: 'vraagteken.jpg',
    // link: '',
    project: "ondergaan",
    status: "none",
  },
  {
    key: 1618100,
    name: "Uw locatie?",
    location: "Uw plaats?",
    description: "Overstijgen",
    period: "Neem gerust contact op",
    // image: 'vraagteken.jpg',
    // link: '',
    project: "overstijgen",
    status: "none",
  },
];
