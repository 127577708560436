export default {
  balans: [
    {
      image: "balans-mannelijk-75-x-120_2.jpg",
      type: "Olieverf",
      description: "Balans mannelijk",
      price: "120x75x2: €1550",
      isRecent: true,
    },
    {
      image: "balans-vrouwelijk-75-x-120_1.jpg",
      type: "Olieverf",
      description: "Balans vrouwelijk",
      price: "120x75x2: €1550",
      isRecent: true,
    },
  ],
  goudenGloed: [
    {
      image: "goudengloed-rorschach-01-links-60x120.jpg",
      type: "Mix",
      description: "GoudenGloed Rorschach links, 2023",
      price: "120x60x2: €1280",
      isRecent: false,
    },
    {
      image: "goudengloed-rorschach-01-rechts-60x120.jpg",
      type: "Mix",
      description: "GoudenGloed Rorschach rechts, 2023",
      price: "120x60x2: €1280",
      isRecent: false,
    },
    {
      image: "goudengloed-transformatie-01-90x140.jpg",
      type: "Mix",
      description: "GoudenGloed transformatie 01, 2023",
      price: "140x90x2: €1890",
      isRecent: false,
    },
    {
      image: "goudengloed-transformatie-02-80x100.jpg",
      type: "Mix",
      description: "GoudenGloed transformatie 02, 2023",
      price: "80x100x2: €1390",
      isRecent: false,
    },
  ],
  paradijsvogels: [
    {
      image: "OVERSTIJGEN parardijsvogels 01.jpg",
      type: "Aquarel",
      description: "Paradijsvogel 1",
      price: "21x27: €285 | 33x45: €445 ",
    },
    {
      image: "OVERSTIJGEN parardijsvogels 02.jpg",
      type: "Aquarel",
      description: "Paradijsvogel 2",
      price: "21x27: €285 | 33x45: €445 ",
    },
    {
      image: "OVERSTIJGEN parardijsvogels 03.jpg",
      type: "Aquarel",
      description: "Paradijsvogel 3",
      price: "21x27: €285 | 33x45: €445 ",
    },
    {
      image: "OVERSTIJGEN parardijsvogels 04.jpg",
      type: "Aquarel",
      description: "Paradijsvogel 4",
      price: "21x27: €285 | 33x45: €445 ",
    },
    {
      image: "OVERSTIJGEN parardijsvogels 05.jpg",
      type: "Aquarel",
      description: "Paradijsvogel 5",
      price: "21x27: €285 | 33x45: €445 ",
    },
  ],
  eiMetFoetus: [
    {
      image: "Ei met foetus - 01.jpg",
      type: "Aquarel",
      description: "Ei met foetus 1",
      price: "€225",
    },
    {
      image: "Ei met foetus - 02.jpg",
      type: "Aquarel",
      description: "Ei met foetus 2",
      price: "€225",
    },
    {
      image: "Ei met foetus - 03.jpg",
      type: "Aquarel",
      description: "Ei met foetus 3",
      price: "€225",
    },
  ],
  swingingBeauties: [
    {
      image: "Swinging beauty 2023 01  20x30.jpg",
      type: "Aquarel",
      description: "Swinging beauty 01",
      price: "origineel: 20x30 | met lijst 30x40 | €362",
    },
    {
      image: "Swinging beauty 2023 02  20x30 lijst.jpg",
      type: "Aquarel",
      description: "Swinging beauty 02",
      price: "origineel: 20x30 | met lijst 30x40 | €362",
    },
    {
      image: "Swinging beauty 2023 03  20x30.jpg",
      type: "Aquarel",
      description: "Swinging beauty 03",
      price: "origineel: 20x30 | met lijst 30x40 | €362",
    },
    {
      image: "Swinging beauty 2023 04  20x30.jpg",
      type: "Aquarel",
      description: "Swinging beauty 04",
      price: "origineel: 20x30 | met lijst 30x40 | €362",
    },
    {
      image: "Swinging beauty 2023 05  20x30.jpg",
      type: "Aquarel",
      description: "Swinging beauty 05",
      price: "origineel: 20x30 | met lijst 30x40 | €362",
    },
    {
      image: "Swinging beauty 2023 06  20x30.jpg",
      type: "Aquarel",
      description: "Swinging beauty 06",
      price: "origineel: 20x30 | met lijst 30x40 | €362",
    },
    // {
    //   image: "Swinging beauty 2023 01  20x30.jpg",
    //   type: "Aquarel",
    //   description: "Swinging beauty 01",
    //   price: "50x20: €144 | 80x30: €288 | 120x46: €567",
    // },
  ],
  aluprints: [
    {
      image: "AAN TAFEL frame 01.jpg",
      type: "Aluprint",
      description: "Frame 1",
      price: "50x20: €144 | 80x30: €288 | 120x46: €567",
    },
    {
      image: "AAN TAFEL frame 02.jpg",
      type: "Aluprint",
      description: "Frame 2",
      price: "50x20: €144 | 80x30: €288 | 120x46: €567",
    },
    {
      image: "AAN TAFEL frame 03.jpg",
      type: "Aluprint",
      description: "Frame 3",
      price: "50x20: €144 | 80x30: €288 | 120x46: €567",
    },
    {
      image: "AAN TAFEL frame 04.jpg",
      type: "Aluprint",
      description: "Frame 4",
      price: "50x20: €144 | 80x30: €288 | 120x46: €567",
    },
    {
      image: "AAN TAFEL frame 05.jpg",
      type: "Aluprint",
      description: "Frame 5",
      price: "50x20: €144 | 80x30: €288 | 120x46: €567",
    },
    {
      image: "AAN TAFEL frame 06.jpg",
      type: "Aluprint",
      description: "Frame 6",
      price: "50x20: €144 | 80x30: €288 | 120x46: €567",
    },
  ],
};
