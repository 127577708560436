export default [
    {
      person: 'Anna',
      text: 'Awesome! Het concept, de realisatie. Proces – dynamiek. Alles zit erin. Het prachtige Bijbelverhaal over, Jezus… en óns leven van geboorte tot de dood, en alle zin die tussen die 2 momenten zit. Dank. Verrijkend ',
      location: 'Rekem',
      date: 'feb 2020',
      project: 'AAN TAFEL',
    },
    {
      person: 'Norman',
      text: 'Prachtig, indrukwekkend',
      location: 'Geertruidenberg',
      date: 'feb 2020',
      project: 'AAN TAFEL',
    },
    {
      person: 'Piet',
      text: 'Wat mooi!! Hierin ben je zelfs in deze coronatijd bezig… Nodigt ons uit aan tafel. Discipelen, wat gaan we doen? Dankjewel!',
      location: 'Geertruidskerk - Geertruidenberg',
      date: 'juni 2021',
      project: 'AAN TAFEL',
    },
    {
      person: 'Michel',
      text: 'Héél bijzonder! Ontroerend!',
      location: 'Museum Valkenburg',
      date: 'feb 2020',
      project: 'AAN TAFEL',
    },
    {
      person: 'Lia',
      text: 'Tot in mijn ziel geraakt',
      location: 'Museum Valkenburg',
      date: 'feb 2020',
      project: 'AAN TAFEL',
    },
    {
      person: 'Hetty',
      text: 'Steeds weer zie ik nieuwe dingen, heel bijzonder!',
      location: 'Museum Valkenburg',
      date: 'feb 2020',
      project: 'AAN TAFEL',
    },
    {
      person: 'Maria',
      text: 'Een geweldige ervaring, indrukwekkend',
      location: 'Museum Valkenburg',
      date: 'feb 2020',
      project: 'AAN TAFEL',
    },
    {
      person: 'Jerry',
      text: 'In één woord geweldig! Een kunstwerk met enorm veel diepgang. Daar waar het om gaat in uw leven! Bijzonder talent',
      location: 'Museum Valkenburg',
      date: 'feb 2020',
      project: 'AAN TAFEL',
    },
    {
      person: 'Gerrit',
      text: 'Geweldig, er zit zoveel in',
      location: 'Geertruidskerk - Geertruidenberg',
      date: 'juni 2021',
      project: 'AAN TAFEL',
    },
    {
      person: 'Isabele',
      text: 'Heel bijzonder. De symboliek en de relatie tussen alle elementen is zeer indrukwekkend!',
      location: 'Geertruidskerk - Geertruidenberg',
      date: 'juni 2021',
      project: 'AAN TAFEL',
    },
    {
      person: 'Isabele',
      text: 'Erg mooi en indrukwekkend hoe het doek en de projectie samenwerken! Een goed geslaagd project om trots op te zijn',
      location: 'Geertruidskerk - Geertruidenberg',
      date: 'juni 2021',
      project: 'AAN TAFEL',
    },
    {
      person: 'Wim',
      text: 'Een nieuwe dimensie toegevoegd aan het laatste avondmaal. Tof en zeer apart',
      location: 'Geertruidskerk - Geertruidenberg',
      date: 'juni 2021',
      project: 'AAN TAFEL',
    },
    {
      person: 'Monique',
      text: 'Om bij stil te staan, prachtig en vol hoop!',
      location: 'Geertruidskerk - Geertruidenberg',
      date: 'juli 2021',
      project: 'AAN TAFEL',
    },
    {
      person: 'Jacqueline',
      text: 'Tranen in mijn ogen',
      location: 'Geertruidskerk - Geertruidenberg',
      date: 'juli 2021',
      project: 'AAN TAFEL',
    },
    {
      person: 'Bas',
      text: 'Inspirerend, op zijn minst. We zijn weer mooi aan het denken gezet, waarvoor dank!',
      location: 'Geertruidskerk - Geertruidenberg',
      date: 'juli 2021',
      project: 'AAN TAFEL',
    },
    {
      person: 'Marie',
      text: 'Een heerlijk religieus spektakel. Chapeau',
      location: 'Geertruidskerk - Geertruidenberg',
      date: 'aug 2021',
      project: 'AAN TAFEL',
    },
    {
      person: 'Margot',
      text: 'Onze eerste ervaring met deze uiting van kunst. Om niet te vergeten, dit móét naar een groter podium!',
      location: 'Geertruidskerk - Geertruidenberg',
      date: 'aug 2021',
      project: 'AAN TAFEL',
    },
    {
      person: 'Jan',
      text: 'Geraakt… Dank voor zoveel moois',
      location: 'Oud-Rekem (B)',
      date: 'aug 2021',
      project: 'AAN TAFEL',
    },
    {
      person: 'Ray',
      text: 'Wat fantastisch, een stap naar de toekomst',
      location: 'Oud-Rekem (B)',
      date: 'aug 2021',
      project: 'AAN TAFEL',
    },
    {
      person: 'Linda',
      text: 'Heel indrukwekkend, een spirituele ervaring, westers en oosters bij elkaar',
      location: 'Oud-Rekem (B)',
      date: 'aug 2021',
      project: 'AAN TAFEL',
    },
    {
      person: 'Joke',
      text: 'Nog nooit zo`n prachtige installatie gezien. Alles klopte: beeld / kleur / muziek. Twee keer achter elkaar bekeken. Complimenten ',
      location: 'Oud-Rekem (B)',
      date: 'aug 2021',
      project: 'AAN TAFEL',
    },
    {
      person: 'José',
      text: 'Wij zijn sprakeloos. Schitterend werk. Het laatste avondmaal niet alleen voor jou en mij maar voor ons allemaal',
      location: 'Oud-Rekem (B)',
      date: 'sept 2021',
      project: 'AAN TAFEL',
    },
    {
      person: 'Nikkie',
      text: 'Extraordinaire',
      location: 'Oud-Rekem (B)',
      date: 'sept 2021',
      project: 'AAN TAFEL',
    },
    {
      person: 'Marie José',
      text: 'Geweldig, fantastisch. De hele combinatie. Chapeau',
      location: 'Oud-Rekem (B)',
      date: 'sept 2021',
      project: 'AAN TAFEL',
    },
    {
      person: 'Carina',
      text: 'Schitterende presentatie. Prikkelen van zintuigen. Mooie kleur en beweging. Prachtige muziek. Ga zo door!',
      location: 'Oud-Rekem (B)',
      date: 'sept 2021',
      project: 'AAN TAFEL',
    },
    ///////////////////////////////////////// OVERSTIJGEN  /////////////////////////////////////////
    {
      person: 'Karen & Alfons',
      text: 'Gekomen uit TIlburg, met veel plezier genoten van deze galerie. Met een heel mooi thema, overstijgen. De moeite waard om deze trip te maken. Hopelijk vinden veel mensen deze galerie',
      location: 'Galerie Peter Slegers',
      date: 'juni 2022',
      project: 'OVERSTIJGEN',
    },
    {
      person: 'Marion',
      text: 'Wij hebben dit zeer gewaardeerd',
      location: 'Galerie Peter Slegers',
      date: 'juni 2022',
      project: 'OVERSTIJGEN',
    },
    {
      person: 'Chantal',
      text: 'Ronduit mooi om te zien. Altijd weer verassende creaties!',
      location: 'Galerie Peter Slegers',
      date: 'juni 2022',
      project: 'OVERSTIJGEN',
    },
    {
      person: 'Jos',
      text: 'Even Goddelijk tot rust komen',
      location: 'Galerie Peter Slegers',
      date: 'juni 2022',
      project: 'OVERSTIJGEN',
    },
    {
      person: 'Judith',
      text: 'Wat een belevenis!',
      location: 'Galerie Peter Slegers',
      date: 'juni 2022',
      project: 'OVERSTIJGEN',
    },
    {
      person: 'Jules',
      text: 'Zeer, zeer indrukwekkend. Grootse complimenten voor jullie werk',
      location: 'Galerie Peter Slegers',
      date: 'juni 2022',
      project: 'OVERSTIJGEN',
    },
    {
      person: 'Veronique',
      text: 'Ik ben "gevangen" door de onbevangen werken die we hier zijn. De vrije vogel in mij huist, neemt weer een stukje bevrijding mee. Stof tot nadenken!',
      location: 'Galerie Peter Slegers',
      date: 'juni 2022',
      project: 'OVERSTIJGEN',
    },
    {
      person: 'Jan & Loes',
      text: 'Wat een geweldige beleving is deze expositie... De vele vormen van uitingen, de verassende combinatie en harmonie. Een herkenning van geesten met liefde voor de kunst!',
      location: 'Galerie Peter Slegers',
      date: 'juni 2022',
      project: 'OVERSTIJGEN',
    },
    {
      person: 'Sonja',
      text: 'Prachtig!',
      location: 'Galerie Peter Slegers',
      date: 'juni 2022',
      project: 'OVERSTIJGEN',
    },
    {
      person: 'Bert & Suzanne',
      text: 'Het totaalproject oversteeg onze verwachtingen, prachtig werk!',
      location: 'Galerie Peter Slegers',
      date: 'juni 2022',
      project: 'OVERSTIJGEN',
    },
    {
      person: 'Sandra',
      text: 'Samenwerken is verbinden',
      location: 'Galerie Peter Slegers',
      date: 'juni 2022',
      project: 'OVERSTIJGEN',
    },
    {
      person: 'Evelyn',
      text: 'Zo mooi om een kijkje te mogen nemen in jullie artistieke denken. De totaalbeleving is veel te omvattend om dit onder woorden te brengen!',
      location: 'Galerie Peter Slegers',
      date: 'juni 2022',
      project: 'OVERSTIJGEN',
    },
    {
      person: 'Jenny & Frans',
      text: 'Laten wij ons bewust zijn van onze vrijheid, ook wij zijn vrije vogels en zoals wij nu bezig zijn zitten we in de gevangenis met de deuren wagenwijd open. Bedankt voor deze ontmoeting!',
      location: 'Galerie Peter Slegers',
      date: 'juni 2022',
      project: 'OVERSTIJGEN',
    },
    {
      person: 'Hans',
      text: 'Een drieluik, uitgelezen om drie makers amen te laten vloeien tot de prachtige expositie van jullie gezamenlijke werk, prachtig!',
      location: 'Galerie Peter Slegers',
      date: 'juni 2022',
      project: 'OVERSTIJGEN',
    },
    {
      person: 'Sjaak',
      text: 'Heel mooi werk van een stel hele mooie mensen!',
      location: 'Galerie Peter Slegers',
      date: 'juni 2022',
      project: 'OVERSTIJGEN',
    },
    {
      person: 'Henk & Martin',
      text: 'Heel mooi & indrukwekkend',
      location: 'Galerie Peter Slegers',
      date: 'juni 2022',
      project: 'OVERSTIJGEN',
    },
    {
      person: 'Nelleke',
      text: 'Fascinerend',
      location: 'Galerie Peter Slegers',
      date: 'juni 2022',
      project: 'OVERSTIJGEN',
    },

  ]