import Vue from "vue";
import Vuex from "vuex";
import router from "../router";

import destinations from "./destinations.js";
import newsItems from "./newsItems.js";
import pressItems from "./pressItems.js";
import reviews from "./reviews.js";
import shop from "./shop";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    destinations,
    newsItems,
    pressItems,
    reviews,
    shop,
    thisNewsItem: null,
  },
  getters: {
    destinationsNEW(state) {
      return state.destinationsNEW;
    },
    destinations(state) {
      return state.destinations;
    },
    newsItems(state) {
      return state.newsItems;
    },
    pressItems(state) {
      return state.pressItems;
    },
    thisNewsItem(state) {
      return state.thisNewsItem;
    },
    reviews(state) {
      return state.reviews;
    },
    shop(state) {
      return state.shop;
    },
  },
  mutations: {
    updateNewsItem(state, payload) {
      state.thisNewsItem = payload;
    },
  },
  actions: {
    setNewsItem(context, item) {
      context.commit("updateNewsItem", item);
      const article = item.title.replace(/\s/g, "").toLowerCase();
      // router.go({name: 'Newsview'})
      // router.push("/kalender");
      // router.push({ name: 'Newsview', params: { article: article } })
    },
  },
  modules: {},
});
