// import Vue from 'vue'
// import VueRouter from 'vue-router'
// // import  Store  from '../store'

// Vue.use(VueRouter)

// // const newsItem = store.state.getters.thisNewsItem.title
// // console.log(Store.getters.thisNewsItem.title)

export const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "about" */ "@/views/Home.vue"),
  },
  {
    path: "*",
    // name: 'Aantafel',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "@/views/Home.vue"),
  },
  {
    path: "/trilogica",
    name: "Trilogica",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/Trilogica.vue"),
  },
  {
    path: "/aantafel",
    name: "Aantafel",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/Aantafel.vue"),
  },
  //   {
  //     path: '/aantafel/media',
  //     name: 'Aan tafel - media',
  //     // route level code-splitting
  //     // this generates a separate chunk (about.[hash].js) for this route
  //     // which is lazy-loaded when the route is visited.
  //     component: () => import(/* webpackChunkName: "about" */ '@/views/AantafelMedia.vue')
  //   },
  {
    path: "/ondergaan",
    name: "Ondergaan",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/Ondergaan.vue"),
  },

  {
    path: "/overstijgen",
    name: "Overstijgen",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/Overstijgen.vue"),
  },
  {
    path: "/kalender",
    name: "Calendar",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/Calendar.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/Contact.vue"),
  },
  {
    path: "/overmij",
    name: "Aboutme",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/Aboutme.vue"),
  },
  {
    path: "/pers",
    name: "Press",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/Press.vue"),
  },
  {
    path: "/nieuwsbrief",
    name: "News",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "@/views/News.vue"),
  },
  {
    path: "/nieuwsbrief/:article",
    name: "Newsview",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/Newsview.vue"),
    props: true,
    meta: { sitemap: { ignoreRoute: true } },
  },
  {
    path: "/example",
    name: "Example",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/example.vue"),
  },
  {
    path: "/artotheek",
    name: "Artotheek",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/Artotheek.vue"),
  },
  {
    path: "/galerie",
    name: "Gallery",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/Gallery.vue"),
  },
  {
    path: "/steun",
    name: "Donation",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/Donation.vue"),
  },
  {
    path: "/mediamap",
    name: "Mediamap",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/Mediamap.vue"),
    meta: { sitemap: { ignoreRoute: true } },
  },
  {
    path: "/boekingen",
    name: "Bookings",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/Bookings.vue"),
  },
];

// temporarely for testing purposes
// const baseURL = process.env.BASE_URL + 'peterslegers/'

// const router = new VueRouter({
//   mode: 'history',
//   // base: baseURL,
//   base: process.env.BASE_URL,
//   routes,
//   scrollBehavior (to, from, savedPosition) {
//     if (savedPosition) {
//       return savedPosition
//     } else {
//       return { x: 0, y: 0 }
//     }
//   }

// })
